<template>
  <div class="result">
    <page-title />
    <div class="result-box">
      <div class="result-title">{{ showMessage.text }}</div>
      <!-- <div class="result-text">{{ showMessage.text }}</div> -->
      <div
        v-if="result && result.hasOwnProperty('status')"
        class="result-score-title"
      >
        本场分数:
      </div>
      <div
        v-if="result && result.hasOwnProperty('status')"
        class="result-score"
      >
        {{ result.score }}
      </div>
      <!-- <img
        class="button"
        :src="require('@/assets/button3.png')"
        alt=""
        @click="$router.replace('/home')"
      /> -->
      <!-- <van-button
        v-if="result.nowScore"
        type="info"
        round
        block
        @click="$router.replace('/lucky')"
      >
        去抽奖
      </van-button> -->
      <img
        v-if="result.nowScore && result.nowScore > 0"
        class="button"
        :src="require('@/assets/button4.png')"
        alt=""
        @click="$router.replace('/lucky')"
      />
      <div class="result-rank" @click="$router.replace('/home')">
        <img :src="require('@/assets/icons/rule.png')" alt="" />
        <span>回到首页</span>
      </div>
    </div>
  </div>
</template>
<script>
// import { Toast } from 'vant'
import storage from 'store'

export default {
  components: {},
  data() {
    return {
      // 要展示的消息
      showMessage: {},
      successMessage: [
        {
          title: '你真棒！加油冲榜！',
          text: '你真棒！可以抽奖1次！'
        }
      ],
      failMessage: [
        {
          title: '很遗憾，答题失败',
          text: '要加油哦！去分享可得1次抽奖资格！'
        }
      ],
      timeOutMessage: [
        {
          title: '很遗憾，答题失败',
          text: '时间到了！去分享可得1次抽奖资格！'
        }
      ]
    }
  },
  computed: {
    result() {
      const result = storage.get('ANSWER_RESULT') || {}
      return result
    },
    user() {
      return this.$store.state.user
    }
  },
  created() {},
  mounted() {
    if (storage.get('ANSWER_RELOAD')) {
      storage.set('ANSWER_RELOAD', false)
      window.location.reload()
    }
    // this.getWxConfig()
    // if (this.result.id) {
    //   storage.set('ANSWER_QUESTIONID', this.result.id)
    //   this.getWxConfig()
    // }
    this.$store.commit('SET_QUESTIONS', {})
    this.$store.commit('UPDATE_GAME_STATUS', false)
    // eslint-disable-next-line no-prototype-builtins
    if (!this.result || !this.result.hasOwnProperty('status')) {
      // this.$router.replace('/home')
      this.getRandomMessage(this.timeOutMessage)
    } else {
      // this.reload()
      if (this.result.isContinue == 1) {
        this.getRandomMessage(this.timeOutMessage)
      } else if (this.result.score == 100) {
        this.getRandomMessage(this.successMessage)
      } else {
        this.getRandomMessage(this.failMessage)
      }
    }
  },
  beforeDestroy() {
    // this.$store.commit('SET_RESULT', {})
  },
  methods: {
    // callBack() {},
    // getWxConfig() {
    //   const questionId = storage.get('ANSWER_QUESTIONID')
    //   this.$http({
    //     url: '/xt-exam/etUser/getWxShareConfig',
    //     params: {
    //       url: window.location.href.split('#')[0]
    //     }
    //   }).then(response => {
    //     console.log(response)
    //     if (response.code == 200) {
    //       window.wx.config({
    //         // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
    //         // debug: true,
    //         ...response.data,
    //         // 必填，需要使用的 JS 接口列表
    //         jsApiList: [
    //           'updateAppMessageShareData',
    //           'updateTimelineShareData',
    //           'onMenuShareTimeline',
    //           'onMenuShareAppMessage'
    //         ]
    //       })
    //       window.wx.ready(() => {
    //         window.wx.updateAppMessageShareData({
    //           title: '综合监管 健康相伴',
    //           desc:
    //             this.user.nickname +
    //             '邀请你一起参加江苏省医疗卫生行业综合监管2023网络知识竞赛，抽取红包！',
    //           link:
    //             'https://answer.microzl.com?shareId=' +
    //             questionId +
    //             '&date=' +
    //             new Date().getTime(),
    //           imgUrl:
    //             'https://zhiye.nj12320.org:7780/exam-cdn/exam/exam_1701526446144_2023-12-02_448.png',
    //           success: this.callBack
    //         })
    //         window.wx.updateTimelineShareData({
    //           title: '综合监管 健康相伴',
    //           desc:
    //             this.user.nickname +
    //             '邀请你一起参加江苏省医疗卫生行业综合监管2023网络知识竞赛，抽取红包！',
    //           link:
    //             'https://answer.microzl.com?shareId=' +
    //             questionId +
    //             '&date=' +
    //             new Date().getTime(),
    //           imgUrl:
    //             'https://zhiye.nj12320.org:7780/exam-cdn/exam/exam_1701526446144_2023-12-02_448.png',
    //           success: this.callBack
    //         })
    //         window.wx.onMenuShareTimeline({
    //           title: '综合监管 健康相伴',
    //           link:
    //             'https://answer.microzl.com?shareId=' +
    //             questionId +
    //             '&date=' +
    //             new Date().getTime(),
    //           imgUrl:
    //             'https://zhiye.nj12320.org:7780/exam-cdn/exam/exam_1701526446144_2023-12-02_448.png',
    //           success: this.callBack
    //         })

    //         window.wx.onMenuShareAppMessage({
    //           title: '综合监管 健康相伴',
    //           desc:
    //             this.user.nickname +
    //             '邀请你一起参加江苏省医疗卫生行业综合监管2023网络知识竞赛，抽取红包！',
    //           link:
    //             'https://answer.microzl.com?shareId=' +
    //             questionId +
    //             '&date=' +
    //             new Date().getTime(),
    //           imgUrl:
    //             'https://zhiye.nj12320.org:7780/exam-cdn/exam/exam_1701526446144_2023-12-02_448.png',
    //           success: this.callBack
    //         })
    //       })
    //     }
    //   })
    // },
    getRandomMessage(array) {
      this.showMessage = array[Math.floor(Math.random() * array.length)]
    }
  }
}
</script>
<style lang="less" scoped>
.result {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;

  .result-box {
    background: #ffffff;
    position: relative;
    flex: 1;
    width: 100vw;
    /deep/.van-button {
      width: 80%;
      margin: 0 auto;
    }
  }

  .result-title {
    font-size: 1.79rem;
    padding: 0 5vw;
    font-family: PingFang SC;
    font-weight: 800;
    color: #010e4f;
    margin-top: 10vh;
    margin-bottom: 2vh;
  }

  .result-text {
    font-size: 1.04rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #010e4f;
    margin-top: 2vh;
  }

  .result-score-title {
    font-size: 1.04rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #010e4f;
    margin-top: 0.5vh;
  }

  .result-score {
    font-size: 3.27rem;
    font-family: PingFang SC;
    font-weight: 800;
    color: #010e4f;
    margin-top: 2vh;
  }

  .button {
    margin-top: 4vh;
    width: 56vw;
    cursor: pointer;
  }

  .result-rank {
    font-size: 1.04rem;
    font-family: PingFang SC;
    font-weight: 800;
    color: #010e4f;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 3vh;
    img {
      width: 1.29rem;
      height: 1.29rem;
      margin-right: 0.46rem;
    }
  }
}
</style>
